import { cep_getData } from '@/services/Request/CEP';
import { errorHandler } from '@/services/Request/error';

export default {
    namespace: 'model_location',
    state: {
        requestLoading: false,
        locationData: [],
        requestError: false,
        locationRef: null,
    },
    reducers: {
        setLocationRef(state, { payload: locationRef }) {
            return {
                ...state,
                ...locationRef,
            };
        },
        setRequestStatus(state, { payload: requestError }) {
            return {
                ...state,
                requestError,
            };
        },
        handlerGetLocationData(state, { payload: dados }) {
            return {
                ...state,
                locationData: dados,
            };
        },
        handlerRequestLoading(state, { requestLoading }) {
            return {
                ...state,
                requestLoading,
            };
        },
        handlerRequestError(state, { payload: error }) {
            errorHandler(error);
            return {
                ...state,
            };
        },
    },
    effects: {
        *getLocationData({ payload: data }, { call, put }) {
            yield put({ type: 'spin/setSpinning', payload: true });
            try {
                const { checkAddressUpdated, locationFormRef, residencialCep, optionalCep, onlyUpdateCity } = data;
                if (residencialCep) {
                    const residencialResponse = yield call(cep_getData, residencialCep);
                    locationFormRef.getEnderecoResidencialData(residencialResponse, onlyUpdateCity ?? false);
                }
                if (optionalCep) {
                    const optionalResponse = yield call(cep_getData, optionalCep);
                    locationFormRef.getEnderecoEntregaData(optionalResponse);
                }
                yield call(checkAddressUpdated);
            } catch (error) {
                yield put({
                    type: 'handlerRequestError',
                    payload: error,
                });
                yield put({ type: 'spin/setSpinning', payload: false });
            }

            yield put({
                type: 'handlerRequestLoading',
                requestLoading: false,
            });
            yield put({ type: 'spin/setSpinning', payload: false });
        },
    },
};
